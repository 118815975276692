import { useState, useEffect } from "react";
import axios from "axios";
import { Video, Image } from "lucide-react";
import HistoryLoaderDialog from "./HistoryLoaderDialog";
import MediaViewer from "./MediaViewer";

const MessagesViewer = ({ bot, selectedState, setMessage }) => {
  const [viewType, setViewType] = useState("channels");
  const [channels, setChannels] = useState([]);
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userChats, setUserChats] = useState([]);

  const [messagesPagination, setMessagesPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    limit: 50,
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [selectedLoadingChannel, setSelectedLoadingChannel] = useState(null);

  useEffect(() => {
    if (selectedState && selectedChannel) {
      fetchMessages(selectedChannel.peer_id);
    }
  }, [selectedState, selectedChannel]);

  useEffect(() => {
    fetchChannels();
  }, [bot?.id]);

  const parseChannelFromAPI = (data) => {
    if (!data?.success) return { channels: [], chats: [], userChats: [] };

    // Парсинг даних, які прийшли напряму з Telegram API
    const channels =
      data.channels?.map((channel) => ({
        peer_id: channel.id,
        title: channel.title,
        username: channel.username,
        participants_count: channel.participants_count,
        additional_info: channel,
      })) || [];

    const chats =
      data.all?.[2]
        ?.filter((item) => item._ === "chat")
        .map((chat) => ({
          id: chat.id,
          chat_id: chat.id,
          title: chat.title,
          participants_count: chat.participants_count,
          is_forum: chat.forum,
          deactivated: chat.deactivated,
          additional_info: chat,
        })) || [];

    const userChats =
      data.all?.[3]
        ?.filter((item) => item._ === "user")
        .map((user) => ({
          id: user.id,
          user_chat_id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          username: user.username,
          phone: user.phone,
          bot: user.bot,
          verified: user.verified,
          deleted: user.deleted,
          additional_info: user,
        })) || [];

    return { channels, chats, userChats };
  };

  const parseChannelFromDB = (data) => {
    if (!data?.success) return { channels: [], chats: [], userChats: [] };

    // Парсинг даних, які прийшли з нашої бази даних
    const channels =
      data.data
        ?.filter((item) => item.broadcast || item.megagroup)
        .map((channel) => ({
          peer_id: channel.peer_id,
          title: channel.title,
          username: channel.username,
          participants_count: channel.participants_count,
          unread_count: channel.unread_count,
          unread_mentions_count: channel.unread_mentions_count,
          additional_info: channel.additional_info,
        })) || [];

    const chats =
      data.chats?.map((chat) => ({
        id: chat.id,
        chat_id: chat.chat_id,
        title: chat.title,
        participants_count: chat.participants_count,
        is_forum: chat.is_forum,
        deactivated: chat.deactivated,
        additional_info: chat.additional_info,
      })) || [];

    const userChats =
      data.userChats?.map((user) => ({
        id: user.id,
        user_chat_id: user.user_chat_id,
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        phone: user.phone,
        bot: user.bot,
        verified: user.verified,
        deleted: user.deleted,
        additional_info: user.additional_info,
      })) || [];

    return { channels, chats, userChats };
  };

  const fetchChannels = async () => {
    if (!bot?.id) return;
    const userId = selectedState?.user_id;
    if (!userId) return;
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/channels?user_id=${selectedState.user_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Визначаємо формат даних та парсимо відповідно
      const isFromAPI = response.data.all !== undefined;
      const parsedData = isFromAPI
        ? parseChannelFromAPI(response.data)
        : parseChannelFromDB(response.data);

      setChannels(parsedData.channels);
      setChats(parsedData.chats);
      setUserChats(parsedData.userChats);
    } catch (error) {
      console.error("Error fetching channels:", error);
      setMessage("Помилка завантаження каналів");
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (channelId, page = 1) => {
    if (!bot?.id || !channelId) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");

    //  console.log("Fetching messages for channel:", channelId, "page:", page); // Debug log

      // Використовуємо існуючий маршрут для отримання повідомлень каналу
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/messages/channel/${channelId}`,
        {
          params: {
            page,
            limit: messagesPagination.limit,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Server response:", response.data); // Debug log

      if (response.data.success && response.data.data) {
        setMessages(response.data.data.data);
        setMessagesPagination((prev) => ({
          ...prev,
          currentPage: response.data.data.current_page,
          totalPages: response.data.data.last_page,
          total: response.data.data.total,
        }));
      } else {
        console.error("Invalid response format:", response.data);
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessage(
        error.response?.data?.error || "Помилка завантаження повідомлень"
      );
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const loadChannelHistory = async (selectedItem, messageLimit) => {
    if (!bot?.id || !selectedState) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      // Визначаємо peer_id в залежності від типу вибраного елемента
      let peerId;
      if (viewType === "channels") {
        peerId = selectedItem.peer_id;
      } else if (viewType === "chats") {
        peerId = selectedItem.chat_id;
      } else if (viewType === "userChats") {
        peerId = selectedItem.user_chat_id;
      }

      if (!peerId) {
        setMessage("Помилка: Не вдалося визначити ID чату");
        return;
      }

      console.log("Loading history with params:", {
        user_id: selectedState.user_id,
        peer: peerId,
        messageLimit,
      });

      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/messages`,
        {
          user_id: selectedState.user_id,
          peer: peerId,
          offset_id: 0,
          limit: messageLimit,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        setMessage("Історію повідомлень успішно завантажено");
        await fetchMessages(peerId);
      } else {
        setMessage("Помилка при завантаженні історії");
      }
    } catch (error) {
      console.error("Error loading history:", error);
      setMessage(error.response?.data?.error || "Помилка завантаження історії");
    } finally {
      setLoading(false);
      setIsHistoryDialogOpen(false);
    }
  };

  const getChannelType = (channel) => {
    if (!channel.additional_info) return "Невідомий тип";
    const info = channel.additional_info;

    if (info._ === "dialog") return "Діалог";
    if (info._ === "channel") {
      if (info.megagroup) return "Група";
      if (info.broadcast) return "Канал";
      return "Чат";
    }
    return "Невідомий тип";
  };

  const getChannelStatus = (channel) => {
    const statuses = [];
    const info = channel.additional_info || {};

    if (info.left) statuses.push("Покинуто");
    if (info.verified) statuses.push("Верифіковано");
    if (info.restricted) statuses.push("Обмежено");
    if (info.signatures) statuses.push("З підписами");
    if (info.slowmode_enabled) statuses.push("Повільний режим");

    return statuses.join(", ") || "Активний";
  };

  const getChannelTitle = (channel) => {
    if (channel.title) return channel.title;
    if (channel.additional_info?.title) return channel.additional_info.title;
    return `Чат ${channel.peer_id}`;
  };
  const ChannelItem = ({ channel, isSelected, onSelect, onLoadHistory }) => (
    <div
      className={`p-4 rounded-lg border cursor-pointer transition-colors ${
        isSelected ? "bg-blue-50 border-blue-200" : "hover:bg-gray-50"
      }`}
      onClick={onSelect}
    >
      <div className="font-medium mb-2">{getChannelTitle(channel)}</div>
      {channel.username && (
        <div className="text-sm text-blue-600 mb-2">@{channel.username}</div>
      )}

      <div className="grid gap-1 text-sm text-gray-600">
        <DetailRow label="Тип" value={getChannelType(channel)} />
        <DetailRow label="ID" value={channel.peer_id} />
        {channel.participants_count > 0 && (
          <DetailRow label="Учасників" value={channel.participants_count} />
        )}
        <DetailRow label="Статус" value={getChannelStatus(channel)} />
        <DetailRow label="Непрочитано" value={channel.unread_count} />
        {channel.unread_mentions_count > 0 && (
          <DetailRow label="Згадування" value={channel.unread_mentions_count} />
        )}
      </div>

      <LoadHistoryButton
        onClick={(e) => {
          e.stopPropagation();
          onLoadHistory();
        }}
      />
    </div>
  );

  const ChatItem = ({ chat, isSelected, onSelect, onLoadHistory }) => (
    <div
      className={`p-4 rounded-lg border cursor-pointer transition-colors ${
        isSelected ? "bg-blue-50 border-blue-200" : "hover:bg-gray-50"
      }`}
      onClick={onSelect}
    >
      <div className="font-medium mb-2">{chat.title}</div>
      <div className="grid gap-1 text-sm text-gray-600">
        <DetailRow label="Тип" value="Група" />
        <DetailRow label="ID" value={chat.chat_id} />
        <DetailRow label="Учасників" value={chat.participants_count} />
        <DetailRow label="Форум" value={chat.is_forum ? "Так" : "Ні"} />
        <DetailRow
          label="Деактивовано"
          value={chat.deactivated ? "Так" : "Ні"}
        />
      </div>

      <LoadHistoryButton
        onClick={(e) => {
          e.stopPropagation();
          onLoadHistory();
        }}
      />
    </div>
  );

  const UserChatItem = ({ userChat, isSelected, onSelect, onLoadHistory }) => (
    <div
      className={`p-4 rounded-lg border cursor-pointer transition-colors ${
        isSelected ? "bg-blue-50 border-blue-200" : "hover:bg-gray-50"
      }`}
      onClick={onSelect}
    >
      <div className="font-medium mb-2">
        {userChat.first_name} {userChat.last_name}
      </div>
      {userChat.username && (
        <div className="text-sm text-blue-600 mb-2">@{userChat.username}</div>
      )}

      <div className="grid gap-1 text-sm text-gray-600">
        <DetailRow label="ID" value={userChat.user_chat_id} />
        {userChat.phone && <DetailRow label="Телефон" value={userChat.phone} />}
        <DetailRow label="Бот" value={userChat.bot ? "Так" : "Ні"} />
        <DetailRow
          label="Верифіковано"
          value={userChat.verified ? "Так" : "Ні"}
        />
        <DetailRow label="Видалено" value={userChat.deleted ? "Так" : "Ні"} />
      </div>

      <LoadHistoryButton
        onClick={(e) => {
          e.stopPropagation();
          onLoadHistory();
        }}
      />
    </div>
  );

  const DetailRow = ({ label, value }) => (
    <div className="flex justify-between">
      <span>{label}:</span>
      <span className="font-medium">{value}</span>
    </div>
  );

  const LoadHistoryButton = ({ onClick }) => (
    <div className="mt-4 flex justify-end">
      <button
        onClick={onClick}
        className="px-3 py-1 text-sm text-white bg-green-500 rounded hover:bg-green-600 transition-colors"
      >
        Завантажити історію
      </button>
    </div>
  );

  const MessageItem = ({ message, bot, selectedState }) => {
    const parsedMedia = (() => {
      try {
        return typeof message.message_media === "string"
          ? JSON.parse(message.message_media)
          : message.message_media;
      } catch (e) {
        console.error("Error parsing media:", e);
        return null;
      }
    })();
  
    const parsedReactions = (() => {
      try {
        return typeof message.reactions === "string"
          ? JSON.parse(message.reactions)
          : message.reactions;
      } catch (e) {
        console.error("Error parsing reactions:", e);
        return null;
      }
    })();
  
    const mediaType = parsedMedia?._?.toLowerCase();
    const hasDocument = mediaType?.includes("document");
    const hasPhoto = mediaType?.includes("photo");
    const hasVideo = hasDocument && parsedMedia?.document?.attributes?.some(
      (attr) => attr._ === "documentAttributeVideo"
    );
  
    return (
      <div className="p-4 border rounded-lg hover:bg-gray-50">
        <div className="text-sm text-gray-500 mb-2">
          {new Date(message.message_date).toLocaleString()}
        </div>
        
        <div className="space-y-2">
          {(hasPhoto || hasVideo) && (
            <div className="flex gap-2">
              {hasVideo && <Video className="w-5 h-5 text-blue-500" />}
              {hasPhoto && <Image className="w-5 h-5 text-green-500" />}
            </div>
          )}
  
          <div className="text-gray-600">{message.message}</div>
  
          {parsedMedia && (
            <MediaViewer
              message={message}
              bot={bot}
              selectedState={selectedState}
            />
          )}
  
          <div className="flex flex-wrap gap-2 text-sm text-gray-500">
            {message.views > 0 && (
              <span className="bg-gray-100 px-2 py-1 rounded">
                👁 {message.views}
              </span>
            )}
            {message.forwards > 0 && (
              <span className="bg-gray-100 px-2 py-1 rounded">
                🔄 {message.forwards}
              </span>
            )}
            {message.is_channel_post && (
              <span className="bg-blue-100 px-2 py-1 rounded">📢 Пост</span>
            )}
          </div>
  
          {parsedReactions?.results && (
            <div className="flex flex-wrap gap-2 text-sm">
              {parsedReactions.results.map((reaction, idx) => (
                <span key={idx} className="bg-blue-50 px-2 py-1 rounded">
                  {reaction.reaction.emoticon} {reaction.count}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-12 gap-4 mt-4">
      {/* Список каналів */}
      <div className="col-span-4">
        <div className="bg-white rounded-lg shadow p-4">
          {/* Navigation Tabs */}
          <div className="border-b border-gray-200 mb-4">
            <nav className="flex space-x-4">
              <button
                onClick={() => setViewType("channels")}
                className={`py-2 px-4 ${
                  viewType === "channels"
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Канали
              </button>
              <button
                onClick={() => setViewType("chats")}
                className={`py-2 px-4 ${
                  viewType === "chats"
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Групи
              </button>
              <button
                onClick={() => setViewType("userChats")}
                className={`py-2 px-4 ${
                  viewType === "userChats"
                    ? "border-b-2 border-blue-500 text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Користувачі
              </button>
            </nav>
          </div>

          {/* Content based on selected view */}
          <div className="space-y-3">
            {viewType === "channels" &&
              channels.map((channel) => (
                <ChannelItem
                  key={channel.peer_id}
                  channel={channel}
                  isSelected={selectedChannel?.peer_id === channel.peer_id}
                  onSelect={() => {
                    setSelectedChannel(channel);
                    fetchMessages(channel.peer_id);
                  }}
                  onLoadHistory={() => {
                    setSelectedLoadingChannel(channel);
                    setIsHistoryDialogOpen(true);
                  }}
                />
              ))}

            {viewType === "chats" &&
              chats.map((chat) => (
                <ChatItem
                  key={chat.id}
                  chat={chat}
                  isSelected={selectedChannel?.chat_id === chat.chat_id}
                  onSelect={() => {
                    setSelectedChannel(chat);
                    fetchMessages(chat.chat_id);
                  }}
                  onLoadHistory={() => {
                    setSelectedLoadingChannel(chat);
                    setIsHistoryDialogOpen(true);
                  }}
                />
              ))}

            {viewType === "userChats" &&
              userChats.map((userChat) => (
                <UserChatItem
                  key={userChat.id}
                  userChat={userChat}
                  isSelected={
                    selectedChannel?.user_chat_id === userChat.user_chat_id
                  }
                  onSelect={() => {
                    setSelectedChannel(userChat);
                    fetchMessages(userChat.user_chat_id);
                  }}
                  onLoadHistory={() => {
                    setSelectedLoadingChannel(userChat);
                    setIsHistoryDialogOpen(true);
                  }}
                />
              ))}
          </div>
        </div>
      </div>

      {/* Messages Section */}
      <div className="col-span-8">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">
            {selectedChannel
              ? `Повідомлення: ${getChannelTitle(selectedChannel)}`
              : "Виберіть канал для перегляду повідомлень"}
          </h3>

          {loading ? (
            <div className="flex justify-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : selectedChannel ? (
            <>
              <div className="space-y-4">
                {Array.isArray(messages) && messages.length > 0 ? (
                  messages.map((message) => (
                    <MessageItem
                      key={message.id}
                      message={message}
                      bot={bot}
                      selectedState={selectedState}
                    />
                  ))
                ) : (
                  <div className="text-center text-gray-500 py-8">
                    Немає повідомлень для відображення
                  </div>
                )}
              </div>

              {messagesPagination.totalPages > 1 && (
                <div className="mt-4 flex justify-center space-x-2">
                  <button
                    onClick={() =>
                      fetchMessages(
                        selectedChannel.peer_id,
                        messagesPagination.currentPage - 1
                      )
                    }
                    disabled={messagesPagination.currentPage === 1}
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded disabled:bg-gray-300"
                  >
                    Попередня
                  </button>
                  <span className="px-4 py-2 text-sm">
                    Сторінка {messagesPagination.currentPage} з{" "}
                    {messagesPagination.totalPages}
                  </span>
                  <button
                    onClick={() =>
                      fetchMessages(
                        selectedChannel.peer_id,
                        messagesPagination.currentPage + 1
                      )
                    }
                    disabled={
                      messagesPagination.currentPage ===
                      messagesPagination.totalPages
                    }
                    className="px-4 py-2 text-sm bg-blue-500 text-white rounded disabled:bg-gray-300"
                  >
                    Наступна
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="text-center text-gray-500 py-8">
              Виберіть канал зі списку зліва для перегляду повідомлень
            </div>
          )}
        </div>
      </div>
      {selectedLoadingChannel && (
        <HistoryLoaderDialog
          isOpen={isHistoryDialogOpen}
          onClose={() => {
            setIsHistoryDialogOpen(false);
            setSelectedLoadingChannel(null);
          }}
          onConfirm={(messageCount) => {
            loadChannelHistory(selectedLoadingChannel, messageCount);
          }}
          channelTitle={getChannelTitle(selectedLoadingChannel)}
          isLoading={loading}
        />
      )}
    </div>
  );
};

export default MessagesViewer;
