import { useState, useEffect } from "react";
import axios from "axios";
import {
  FileText,
  Music,
  FileSpreadsheet,
  Code,
  File,
  Video,
  Archive,
  Image,
} from "lucide-react";

const MediaViewer = ({ message, bot, selectedState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [mediaInfo, setMediaInfo] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const getMediaUrl = (url) => `${apiUrl}/${url}`;

  useEffect(() => {
    if (message.media && message.media.length > 0) {
      const mediaItem = message.media[0];
      if (mediaItem.url) {
        setMediaUrl(getMediaUrl(mediaItem.url));
        determineMediaType(mediaItem.mime_type, mediaItem);
      }
    }
  }, [message.media]);

  const determineMediaType = (mimeType, mediaData) => {
    setMediaInfo(mediaData);

    if (!mimeType) {
      setMediaType("unsupported");
      return;
    }

    if (mimeType.includes("image")) {
      setMediaType("photo");
    } else if (mimeType.includes("video")) {
      setMediaType("video");
    } else if (mimeType.includes("audio")) {
      setMediaType("audio");
    } else if (mimeType.includes("pdf")) {
      setMediaType("pdf");
    } else if (
      mimeType.includes("spreadsheet") ||
      mimeType.includes("excel") ||
      mimeType.includes("word") ||
      mimeType.includes("text") ||
      mimeType.includes("json") ||
      mimeType.includes("xml")
    ) {
      setMediaType("document");
    } else {
      setMediaType("other");
    }
  };

  const downloadMedia = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");

      const mediaInfo =
        typeof message?.message_media === "string"
          ? JSON.parse(message.message_media)
          : message?.message_media;

      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/download-media`,
        {
          user_id: selectedState.user_id,
          message_media: mediaInfo,
          peer: message.chat_id,
          message_id: message.message_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data.success) {
        const { url, mime_type, file_name, size } = response.data.result;
        if (url) {
          setMediaUrl(url);
          determineMediaType(mime_type, { file_name, size, mime_type });
        }
      }
    } catch (error) {
      console.error("Error downloading media:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const renderFileIcon = (mimeType: string) => {
    if (!mimeType) return <File className="w-6 h-6" />;

    if (mimeType.startsWith("video/"))
      return <Video className="w-6 h-6 text-purple-500" />;
    if (mimeType.startsWith("image/"))
      return <Image className="w-6 h-6 text-blue-500" />;
    if (mimeType.startsWith("audio/"))
      return <Music className="w-6 h-6 text-green-500" />;
    if (mimeType.includes("zip") || mimeType.includes("rar"))
      return <Archive className="w-6 h-6 text-yellow-500" />;
    if (mimeType === "application/pdf")
      return <FileText className="w-6 h-6 text-red-500" />;
    if (mimeType.includes("spreadsheet"))
      return <FileSpreadsheet className="w-6 h-6 text-green-600" />;
    if (mimeType.includes("code") || mimeType.includes("javascript"))
      return <Code className="w-6 h-6 text-purple-600" />;
    if (mimeType.includes("word") || mimeType.includes("text"))
      return <FileText className="w-6 h-6 text-blue-600" />;

    return <File className="w-6 h-6 text-gray-500" />;
  };
  const renderMediaContent = () => {
    if (!mediaUrl) return null;

    switch (mediaType) {
      case "photo":
        return (
          <img
            src={mediaUrl}
            alt="Media content"
            className="max-w-full h-auto rounded"
          />
        );

      case "video":
        return (
          <video controls className="max-w-full rounded">
            <source src={mediaUrl} type="video/mp4" />
            Ваш браузер не підтримує відео
          </video>
        );

      case "audio":
        return (
          <div className="my-2 p-4 bg-gray-50 rounded flex items-center gap-4">
            <Music className="w-6 h-6 text-blue-500" />
            <audio controls className="w-full">
              <source src={mediaUrl} type={mediaInfo?.mime_type} />
              Ваш браузер не підтримує аудіо
            </audio>
          </div>
        );

      case "pdf":
        return (
          <div className="my-2">
            <div className="flex items-center gap-2 mb-2">
              <FileText className="w-6 h-6 text-red-500" />
              <span>{mediaInfo?.file_name || "PDF документ"}</span>
            </div>
            <embed
              src={mediaUrl}
              type="application/pdf"
              width="100%"
              height="600px"
              className="rounded"
            />
          </div>
        );

      case "document":
      case "other":
        return (
          <div className="my-2 p-4 bg-gray-50 rounded flex items-center gap-4">
            {renderFileIcon(mediaInfo?.mime_type)}
            <div className="flex-1 min-w-0">
              <div className="font-medium truncate">
                {mediaInfo?.file_name || "Файл"}
              </div>
              <div className="text-sm text-gray-500 truncate">
                <span className="inline-block">{mediaInfo?.mime_type}</span>
                {mediaInfo?.size && (
                  <span className="inline-block ml-2">
                    • {(mediaInfo.size / 1024).toFixed(1)} KB
                  </span>
                )}
              </div>
            </div>
            <a
              href={mediaUrl}
              download={mediaInfo?.file_name}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 whitespace-nowrap"
              target="_blank"
              rel="noopener noreferrer"
            >
              Завантажити
            </a>
          </div>
        );
      default:
        return <p>Цей тип медіа не підтримується.</p>;
    }
  };

  const originalMedia =
    typeof message?.message_media === "string"
      ? JSON.parse(message.message_media)
      : message?.message_media;

  if (
    (originalMedia?._ === "messageMediaDocument" ||
      originalMedia?._ === "messageMediaPhoto") &&
    !mediaUrl
  ) {
    const docInfo = originalMedia?.document;
    return (
      <div>
        <div className="mb-2 text-sm text-gray-500">
          {docInfo?.mime_type && <span>Тип: {docInfo.mime_type}</span>}
          {docInfo?.size && (
            <span className="ml-2">
              • Розмір: {(docInfo.size / 1024 / 1024).toFixed(4)} MB
            </span>
          )}
        </div>
        <button
          onClick={downloadMedia}
          disabled={isLoading}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
        >
          {isLoading ? "Завантаження..." : "Завантажити медіа"}
        </button>
      </div>
    );
  }

  return renderMediaContent();
};

export default MediaViewer;
